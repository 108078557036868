import React from "react";

import "./styles.css";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

function Footer() {
	return (
		<>
			<div className="footer-main">
				<div className="footer-container">
					<div className="footer-content">
						<div className="footer-navigation">
							<p>Navigation</p>
							<ul>
								<li>
									<HashLink to="/#about-me">about</HashLink>
								</li>
								<li>
									<Link to="/blog">blog</Link>
								</li>
								<li>
									<Link to="/projects">projects</Link>
								</li>
							</ul>
						</div>
						<div className="footer-projects">
							<p>Projects</p>
							<ul>
								<li>
									<Link to="/projects/personal-portfollio">
										personal portfolio
									</Link>
								</li>
								<li>*Myflix</li>
								<li>Movies API</li>
								<li>*Kloud Health</li>
							</ul>
						</div>
						{/* <div className="footer-contact">
							<p>Contact</p>
							<ul>
								<li>Email Me!</li>
								<p></p>
							</ul>
						</div> */}
						<div className="footer-socials">
							<p>Socials</p>
							<ul>
								<a
									href="https://github.com/mrjoeladeniyi"
									target="_blank"
									rel="noreferrer"
								>
									<li>
										<img src="https://res.cloudinary.com/dvmgkpfzk/image/upload/c_scale,w_15/v1695595404/assets/icons/icon__github__n76b0z.png" />{" "}
										Github
									</li>
								</a>
								<a
									href="https://www.linkedin.com/in/joel-adeniyi/"
									target="_blank"
									rel="noreferrer"
								>
									<li>
										<img src="https://res.cloudinary.com/dvmgkpfzk/image/upload/c_scale,w_15/v1695595429/assets/icons/linkedin_d50jaz.png" />
										LinkedIn
									</li>
								</a>
								<a
									href="https://www.instagram.com/joeladeniyi.dev/"
									target="_blank"
									rel="noreferrer"
								>
									<li>
										<img src="https://res.cloudinary.com/dvmgkpfzk/image/upload/c_scale,w_14/v1695595443/assets/icons/instagram_mrd3sn.png" />
										Instagram
									</li>
								</a>
								<a
									href="https://twitter.com/mrjoeladeniyi"
									target="_blank"
									rel="noreferrer"
								>
									<li>
										<img src="https://res.cloudinary.com/dvmgkpfzk/image/upload/c_scale,w_15/v1695595416/assets/icons/twitter_i1tbc5.png" />
										Twitter
									</li>
								</a>
							</ul>
						</div>
					</div>
					<img
						src="https://res.cloudinary.com/dvmgkpfzk/image/upload/c_scale,w_94/v1699358921/assets/big_logo_mexmsn.png"
						alt="joel-adeniyi-logo"
						id="joel-big-logo"
					/>
				</div>
			</div>
		</>
	);
}

export default Footer;
