import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import MoblileNav from "../MobileNav";

function Header() {
	return (
		<>
			<div className="header">
				<nav className="navigation">
					<Link to={"/"}>
						<img
							src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1694528424/assets/joel-logo_ldewto.png"
							alt="joel logo"
						/>
					</Link>
					<ul>
						<li>
							<HashLink smooth to={"/#about-me"}>
								about
							</HashLink>
						</li>
						<li>
							<Link to={"/stack"}>stack</Link>
						</li>
						<li>
							<Link to={"/projects"}>projects</Link>
						</li>
						<li>
							<Link to={"/blog"}>blog</Link>
						</li>
					</ul>
				</nav>
				<nav className="mobile-nav">
					<MoblileNav />
				</nav>
			</div>
		</>
	);
}

export default Header;
