import { useState, useEffect } from "react";
import sanityClient from "../../client";
import "./styles.css";
import { Link } from "react-router-dom";

function AllBlogsPage() {
	const [blogData, setBlogData] = useState(null);

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "post"]{
            title,
            slug,
            mainImage{
              asset->{
                _ref,
                url
              },
              alt
            },
            author->{
              _ref,
              name,
              image{
                    asset->{
                        url
                    }
                }
            },
            description
        }`
			)
			.then((data) => setBlogData(data))
			.catch(console.error);
	}, []);
	// console.log(blogData);
	return (
		<main className="posts-page">
			<div className="posts-page__title">
				<h3>Blogs</h3>
				<img
					src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1694992876/assets/icons/icon__arrow_back__kqrkx3.png"
					alt=""
				/>
			</div>
			<section className="posts-page__cards">
				{blogData &&
					blogData.map((blogs, index) => (
						// <Link key={index} className='post-card-link'>
						<Link to={"/blog/" + blogs.slug.current} key={blogs.slug.current}>
							<div className="post-card-body" id="post-card" key={index}>
								<div className="post-image">
									<img
										src={blogs.mainImage.asset.url}
										alt={blogs.mainImage.alt}
									></img>
								</div>
								<div className="post-card-text">
									<h1>{blogs.title}</h1>
									<p>{blogs.description}</p>
								</div>
								<div className="post-card-footer">
									<img
										src={blogs.author.image.asset.url}
										alt={blogs.author.image.asset.alt}
									/>
									<h2>{blogs.author.name}</h2>
								</div>
								<button>
									{/* <Link
										to={"/blog/" + blogs.slug.current}
										key={blogs.slug.current}
									> */}
									Read
									{/* </Link> */}
								</button>
							</div>
						</Link>
					))}
			</section>
		</main>
	);
}

export default AllBlogsPage;
