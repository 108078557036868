import React from "react";
import "./styles.css";
import About from "../About";
import FeaturedProjects from "../../components/FeaturedProjects";
import ConnectMe from "../ConnectMe";
import { ErrorBoundary } from "react-error-boundary";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import AppError from "../Error/appError";

function Homepage() {
	return (
		<>
			<ErrorBoundary fallback={AppError}>
				{/* <Header/> */}
				<div className="container">
					<div className="landing-page">
						<img
							id="portfolio-image"
							src="https://res.cloudinary.com/dvmgkpfzk/image/upload/c_scale,w_200/v1695604964/assets/joel-avatar-stache_atj58x.png"
							alt="joel in suit"
						/>
						<div className="landing-page-bio">
							<h1>joel adeniyi</h1>
							<h2>
								👋 Hi, i’m a software developer with an eye for clean and
								functional design.
							</h2>
							<div className="landing-page-actions">
								<button className="purple-button">
									<img
										src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1694548798/assets/icons/icon__mail__e0rnbm.png"
										alt="img button"
									/>
									<a href="mailto:hello@joeladeniyi.dev">email me</a>
								</button>

								<button className="yellow-button">
									<img
										src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1694548799/assets/icons/icon__paper_plane_outline__wr6iwh.png"
										alt="img button"
									/>
									<HashLink to="/#connect">connect with me</HashLink>
								</button>
							</div>
						</div>
					</div>
					<About />
					<div className="featured-projects">
						<FeaturedProjects />
					</div>
					<div className="connect-me">
						<ConnectMe />
					</div>
				</div>
			</ErrorBoundary>
		</>
	);
}

export default Homepage;
