import React from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-jsx.min'; // Import JSX grammar

const serializers = {
  types: {
    code: props => {
      // Manually trigger Prism highlighting
      const html = Prism.highlight(
        props.node.code,
        Prism.languages[props.node.language] || Prism.languages.jsx, // Fallback to JSX if the language is not found
        props.node.language
      );
      return (
        <pre>
          <code className={`language-${props.node.language}`} dangerouslySetInnerHTML={{ __html: html }} />
        </pre>
      );
    },
  },
};

export default serializers;

