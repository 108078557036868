import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import SanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import { HashLink } from "react-router-hash-link";
import Spinner from "react-spinner-material";
import "./styles.css";

const builder = imageUrlBuilder(SanityClient);
function urlFor(source) {
	return builder.image(source);
}

function SingleProjectPage() {
	const [singleProjectData, setSingleProjectData] = useState(null);
	const { slug } = useParams();

	useEffect(() => {
		SanityClient.fetch(
			`*[slug.current == "${slug}"]{
			title,
			_id,
			mainImage{
				asset->{
					url,
				}
			},
			body,
		}`
		)
			.then((data) => setSingleProjectData(data[0]))
			.catch(console.error);
	}, [slug]);

	if (!singleProjectData) {
		return (
			<>
				<div className="loading">
					<Spinner
						radius={120}
						color={"#333"}
						stroke={3}
						visible={true}
						className="spinner"
					/>
				</div>
			</>
		);
	}

	return (
		<main className="project-post">
			<Link className="back-button" to="/projects">
				<img
					src="https://res.cloudinary.com/dvmgkpfzk/image/upload/c_scale,w_31/v1695989280/assets/icons/Arrow_Pointing_Left_ak2nf6.png"
					alt=""
				/>
			</Link>
			<article className="project-article">
				<header className="project-header">
					<div className="project-header__hero">
						<h1>{singleProjectData.title}</h1>
						<div className="project-header__hero-details">
							<img
								src={singleProjectData.mainImage.asset.url}
								alt={singleProjectData.title}
							/>
							<p>{singleProjectData.name}</p>
						</div>
					</div>
				</header>
				<div className="project-text">
					<BlockContent
						blocks={singleProjectData.body}
						projectId="juhqv8ob"
						dataset="production"
					/>
				</div>
			</article>
		</main>
	);
}

export default SingleProjectPage;
