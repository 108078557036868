import React from 'react'
import './styles.css'

function About() {
  return (
    <div className='about-me' id='about-me'>
        <div className='about-me-title'>
            <h3 className='about-me-text'>about me<img src='https://res.cloudinary.com/dvmgkpfzk/image/upload/v1694992876/assets/icons/icon__arrow_back__kqrkx3.png'/></h3>
            <p>Just a glimpse</p>
        </div>
        <section className='about-me-images'>
            <img id='joel-and-wife' src='https://res.cloudinary.com/dvmgkpfzk/image/upload/c_scale,w_312/v1694992744/assets/joel-and-wife_gs580e.png' alt='joel and wife'/>
            <img id='joel-on-vacation' src='https://res.cloudinary.com/dvmgkpfzk/image/upload/v1694992801/assets/joel-vacation_oj8gn2.png' alt='joel on vacation'/>
            <img id='joel-coffee' src='https://res.cloudinary.com/dvmgkpfzk/image/upload/v1694992834/assets/joel-coffee_ttvpc1.png' alt='joel coffee'/>
        </section>
        <section className='about-me-desc'>
            <div className='about-me-desc-title'>
                <p className='about-me-desc-title-par'>About</p>
                <p className='about-me-desc-title-text'>Hello world, I’m Joel Adeniyi! <br/>
                    <br/>

                    I have a passion for design and I always look for ways to infuse it into my work while still making user experience flourish. <br/>
                    <br/>

                    In addition to coding, I love playing basketball and exploring the world with my beautiful wife.<br/>
                    <br/>

                    I also love me a good cup of coffee! Can’t start my day without one :) 
                </p>
            </div>

        </section>
        


    </div>
  )
}

export default About