import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './layout/Header';
import reportWebVitals from './reportWebVitals';
import App from './App';
import Homepage from './pages/Homepage';
import About from './pages/About';
import AllBlogsPage from './pages/AllBlogsPage'
import AllProjectsPage from './pages/AllProjectsPage';
import SingleBlogPage from './pages/SingleBlogPage';
import SingleProjectPage from './pages/SingleProjectPage';
import { ErrorBoundary } from "react-error-boundary"

import Layout from './layout';

import {
  BrowserRouter,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import AppError from './pages/Error/appError';
import PageError from './pages/Error/pageNotFound';
import ConnectMe from './pages/ConnectMe';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout/>}>
      <Route path='/' element={<Homepage/>}/>
      <Route path='/blog' element={<AllBlogsPage/>}/>
      <Route path='/blog/:slug' element={<SingleBlogPage/>}/>
      <Route path='/projects' element={<AllProjectsPage/>}/>
      <Route path='/projects/:slug' element={<SingleProjectPage/>}/>
      <Route path='/about-me' element={<About/>}/>
      <Route path='/connect' element={<ConnectMe/>}/>
      <Route path='*' element={<PageError/>}/>
    </Route>
));
  
  
//   [
//   {
//     path: "/",
//     element: <Homepage />,
//   },
//   {
//     path: "/about",
//     element: <About />
//   },
//   {
//     path: "/projects",
//     element: <ProjectsPage />
//   },
//   {
//     path: "/blog",
//     element: <Blog />
//   }
// ]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
