import React from "react";
import "./styles.css";
function ConnectMe() {
	return (
		<>
			<div id="connect">
				<button className="connect-me-button">
					connect
					<img src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1694992876/assets/icons/icon__arrow_back__kqrkx3.png" />
				</button>
			</div>
			<section className="connect-me-text">
				<p>
					Do you have a question or just want to chat? Feel free to email me or
					try finding me with the links below.
				</p>
			</section>
			<div className="call-to-action">
				<a href="https://github.com" target="_blank" rel="noreferrer">
					<button>
						<img
							src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1695595404/assets/icons/icon__github__n76b0z.png"
							alt=""
						/>
						Github
						<img
							src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1695595464/assets/icons/diagonal_arrow_tewssi.png"
							alt=""
						/>
					</button>
				</a>
				<a href="https://twitter.com/mrjoeladeniyi" target="blank">
					<button>
						<img src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1695595416/assets/icons/twitter_i1tbc5.png" />
						Twitter
						<img src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1695595464/assets/icons/diagonal_arrow_tewssi.png" />
					</button>
				</a>
				<a href="https://www.linkedin.com/in/joel-adeniyi/" target="blank">
					<button>
						<img src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1695595429/assets/icons/linkedin_d50jaz.png" />
						LinkedIn
						<img src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1695595464/assets/icons/diagonal_arrow_tewssi.png" />
					</button>
				</a>
				<a href="https://www.instagram.com/joeladeniyi.dev/" target="blank">
					<button>
						<img src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1695595443/assets/icons/instagram_mrd3sn.png" />
						Instagram
						<img src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1695595464/assets/icons/diagonal_arrow_tewssi.png" />
					</button>
				</a>
			</div>
		</>
	);
}

export default ConnectMe;
