import React from "react";
import FeaturedProjects from "../../../components/FeaturedProjects";
import "./styles.css";
import { Link } from "react-router-dom";

function PageError() {
	return (
		<div className="error-body">
			<div className="error-image">
				<img src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1697755477/assets/SpilledCoffee_qpsgic.png" />
			</div>
			<div className="error-info">
				<h1>404: Page Not Found</h1>
				<p>Oops! Nothing to see here. Just spilled coffee</p>
				<div className="error-actions">
					<p>Don't panic though. You can:</p>
					<ul>
						<Link to="/">
							<li>→ Go to Home</li>
						</Link>
						<Link to="/projects">
							<li>→ Explore projects</li>
						</Link>
						<Link to="/blog">
							<li>→ Explore blogs</li>
						</Link>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default PageError;
