import React, { useState, useEffect } from "react";
import sanityClient from "../../client";
import FeaturedProjects from "../../components/FeaturedProjects";
import { Link } from "react-router-dom";
import "./styles.css";

function AllProjectsPage() {
	const [projectData, setProjectData] = useState(null);

	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "project"]{
      title,
      date,
      slug,
      mainImage{
        asset->{
          url,
        }
      },
      description,
      projectType,
      link,
      tags
    }`
			)
			.then((data) => setProjectData(data))
			.catch(console.error);
	}, []);

	return (
		<>
			<main className="all-projects-page">
				<section className="fprojects-card">
					<FeaturedProjects />
				</section>
				<section className="all-projects-card">
					<h3>
						all projects
						<img src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1694992876/assets/icons/icon__arrow_back__kqrkx3.png" />
					</h3>
					<section className="project-card-collection">
						{projectData &&
							projectData.map((project, index) => (
								<div key={index}>
									<div className="project-card">
										<div className="project-card-body">
											<div className="project-card-img">
												<img src={project.mainImage.asset.url} alt="" />
											</div>
											<div className="project-card-text">
												<h3 className="project-card-title">{project.title}</h3>
												{/* <p className='card-description'>my personal portfolio made with React</p> */}
											</div>

											<div className="project-card-back">
												<div className="project-card-back__details">
													<h3>{project.title}</h3>
													<p>{project.description}</p>
												</div>
												<div className="project-card-footer">
													<button>
														<Link
															to={"/projects/" + project.slug.current}
															key={project.slug.current}
														>
															details
														</Link>
													</button>

													<a href={project.link} target="_#">
														<img src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1695708765/assets/icons/white-earth-planet_j9uens.png" />
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
					</section>
				</section>
			</main>
		</>
	);
}

export default AllProjectsPage;
