import React from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import "./styles.css";
function NavLinks() {
	return (
		<div className="links">
			<ul>
				<li>
					<HashLink smooth to={"/#about-me"}>
						about
					</HashLink>
				</li>
				<li>
					<Link to={"/stack"}>stack</Link>
				</li>
				<li>
					<Link to={"/projects"}>projects</Link>
				</li>
				<li>
					<Link to={"/blog"}>blog</Link>
				</li>
			</ul>
		</div>
	);
}

export default NavLinks;
