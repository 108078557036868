import React from "react";
import "./styles.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import NavLinks from "../NavLinks";

function MobileNav() {
	const [open, setOpen] = useState(false);
	const hamburger = (
		<img
			src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1696426151/assets/icons/Hamburger_bcdip7.png"
			style={{ height: "30px" }}
			alt=""
			onClick={() => setOpen(!open)}
			className="menuIcon"
		/>
	);

	const closeIcon = (
		<img
			src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1696426146/assets/icons/Cancel_afwetg.png"
			style={{ height: "30px" }}
			alt=""
			onClick={() => setOpen(!open)}
			className="menuIcon"
		/>
	);

	return (
		<nav className="mobile">
			<Link className="logo" to="/">
				<img
					src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1694528424/assets/joel-logo_ldewto.png"
					alt="joel logo"
					style={{ height: "25px" }}
				/>
			</Link>

			{open ? closeIcon : hamburger}
			{open && <NavLinks />}
		</nav>
	);
}

export default MobileNav;
