import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import "./styles.css";
function Layout() {
	return (
		<div className="layout">
			<Header />
			<main className="main-container">
				<Outlet />
				<div className="push"></div>
			</main>
			<Footer />
		</div>
	);
}

export default Layout;
