import React, { useEffect, useState } from "react";
import "./styles.css";
import sanityClient from "../../client";
import { Link } from "react-router-dom";

function FeaturedProjects(project) {
	const [featuredProjectData, setFeaturedProjectData] = useState(null);
	useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "project" && featured == true]{
            title,
            description,
            slug,
            mainImage {
                asset->{
                    url
                }
            },
            link,
            tags,
        }`
			)
			.then((data) => setFeaturedProjectData(data))
			.catch(console.error);
	});
	return (
		<>
			<section className="featured-projects-header">
				<h3>
					featured projects
					<img
						src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1694992876/assets/icons/icon__arrow_back__kqrkx3.png"
						alt=""
					/>
				</h3>
			</section>
			<section className="featured-projects-card">
				{featuredProjectData &&
					featuredProjectData.map((fproject, index) => (
						<div className="card" key={index}>
							<div className="card-body">
								<div className="card-img">
									<img src={fproject.mainImage.asset.url} alt="" />
								</div>
								<div className="card-text">
									<h3 className="card-title">{fproject.title}</h3>
									{/* <p className='card-description'>my personal portfolio made with React</p> */}
								</div>

								<div className="card-back">
									<div className="card-back__details">
										<h3>{fproject.title}</h3>
										<p>{fproject.description}</p>
									</div>
									<div className="card-footer">
										<Link
											to={"/projects/" + fproject.slug.current}
											key={fproject.slug.current}
										>
											<button>details</button>
										</Link>
										<img
											src="https://res.cloudinary.com/dvmgkpfzk/image/upload/v1695708765/assets/icons/white-earth-planet_j9uens.png"
											alt=""
										/>
									</div>
								</div>
							</div>
						</div>
					))}
			</section>
		</>
	);
}

export default FeaturedProjects;
