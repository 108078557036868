import { createClient } from '@sanity/client'

const client =  createClient ({
    projectId: "juhqv8ob",
    dataset: "production",
    useCdn: true,
    apiVersion: "2021-10-21"


})

export default client;